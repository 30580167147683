<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Period</label>
                    <Dropdown dataKey="month" v-model="filters.period" :loading="loadingDropdownPeriod"
                        :options="dataDropdownPeriod"
                        optionLabel="label" optionValue="month" placeholder="Pilih Bulan"
                        :showClear="false"
                     />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Outlet</label>
                    <Dropdown id="otype_id" dataKey="otype_id" v-model="filters.otype_id" :loading="loadingDropdownOutletType"
                        :options="dataDropdownOutletType"
                        optionLabel="label" optionValue="otype_id" placeholder="Pilih Tipe Outlet"
                        :filter="true" :showClear="true" @filter="searchDropdownOutletType($event, 'filter')"
                     />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Region</label>
                    <Dropdown id="region_id" dataKey="region_id" v-model="filters.region_id" :loading="loadingDropdownRegion"
                        :options="dataDropdownRegion"
                        optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" @change="changeBranch()"
                        :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')"
                     />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Branch</label>
                    <Dropdown id="branch_id" dataKey="branch_id" v-model="filters.branch_id" :loading="loadingDropdownBranch"
                        :options="dataDropdownBranch"
                        optionLabel="branch_name" optionValue="branch_id" placeholder="Pilih Branch"
                        :filter="true" :showClear="true" @filter="searchDropdownBranch($event, 'filter')"
                     />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        const today = new Date();
        const currentMonth = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0');

        return {
            // loading
            loading: false,
            loadingDropdownOutletType: false,
            loadingDropdownRegion: false,
            loadingDropdownBranch: false,
            loadingDropdownPeriod: false,

            // dataDropdown
            dataDropdownOutletType: null,
            dataDropdownRegion: null,
            dataDropdownBranch: null,
            dataDropdownPeriod: null,

            // filter
            filters: {
                otype_id: null,
                region_id: null,
                branch_id: null,
                period: currentMonth,
            },
        }
    },
    created(){
        this.searchDropdownOutletType('');
        this.searchDropdownRegion('');
        this.searchDropdownPeriod();
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownOutletType(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dotype.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownOutletType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-outlet-type-mti',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownOutletType = res.data.data;
                            this.loadingDropdownOutletType = false;
                        } else if (purpose == null) {
                            this.dataDropdownOutletType = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dreg.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/region',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "otype_id" : this.filters.otype_id
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownRegion = res.data.data;
                            this.loadingDropdownRegion = false;
                        } else if (purpose == null) {
                            this.dataDropdownRegion = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeBranch() {
            this.filters.branch_id = null;
            this.dataDropdownBranch = null;
            this.searchDropdownBranch('');
        },
        searchDropdownBranch(event, purpose, valueEdit) {
            setTimeout(() => {

                if(this.filters.region_id){

                    if (valueEdit) {
                        this.$refs.dbranch.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownBranch = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/branch',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "region_id" : this.filters.region_id
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownBranch = res.data.data;
                                this.loadingDropdownBranch = false;
                            } else if (purpose == null) {
                                this.dataDropdownBranch = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        searchDropdownPeriod() {
            setTimeout(() => {
                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/month',
                })
                .then(res => {
                    this.dataDropdownPeriod = res.data.data;
                    this.loadingDropdownPeriod = false;
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

